import React from 'react';
import LogoBlack from './LogoBlack';
import Nav from './Nav';
import MobileMenu from './MobileMenu';
import LogoBox from './LogoBox';
import { AiOutlineInstagram } from 'react-icons/ai';
import { BsFacebook } from 'react-icons/bs'

export default class HeaderOne extends React.Component {
    render(){
        return (
            <>
                <header className="main-header main-header-three clearfix">
                    <div className="main-header-three__wrapper">

                        <div className="main-header-three__top clearfix">
                            <div className="container">
                                <div className="main-header-three__top-inner">
                                    <div className="left">
            
                                    </div>

                                    <div className="right">
                                        <div className="title">
                                            <p>Get Social With Us!</p>
                                        </div>

                                        <ul className="social-links">
                                            <li><a href="https://www.instagram.com/scrub.rinse.co/"><AiOutlineInstagram/></a></li>
                                            <li><a href="https://www.facebook.com/scrubandrinsecleaning/"><BsFacebook/></a></li>
                                            
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="main-header-three__middle">
                            <div className="container">
                                <div className="main-header-three__middle-inner">
                                    <LogoBox />

                                    <div className="contact-info">
                                        <ul>
                                            <li>
                                                <div className="icon-box">
                                                    <span className="icon-pin" ></span>
                                                </div>
                                                <div className="text-box">
                                                    <h3>Our Location</h3>
                                                    <p>Serving Metro Detroit</p>
                                                </div>
                                            </li>

                                            <li>
                                                <div className="icon-box">
                                                    <span className="icon-email"></span>
                                                </div>
                                                <div className="text-box">
                                                    <h3>Email Us</h3>
                                                    <p className="email"><a href="mailto:scrub.rinse.co@gmail.com">Scrub & Rinse Cleaning</a>
                                                    </p>
                                                </div>
                                            </li>

                                            <li>
                                                <div className="icon-box">
                                                    <span className="icon-phone-call"></span>
                                                </div>
                                                <div className="text-box">
                                                    <h3>Call Us</h3>
                                                    <p className="number"><a href="tel:7347894178">(734) 789-4178</a></p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="main-header-three__bottom">
                            <div className="container">
                                <div className="main-header-three__bottom-inner">
                                    <div className="main-header-one__bottom-left">
                                        {/* Conditionally render the MobileMenu component */}
                                        <nav className="main-menu main-menu--1">
                                            <div className="main-menu__inner">
                                                <div className="mobile-menu">
                                                    <MobileMenu />
                                                </div>
                                                <div className="desktop-menu">
                                                    <Nav />
                                                </div>
                                            </div>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </header>
            </>
        )
    }
}