import React from 'react';
import { FaRegLightbulb, FaToilet  } from 'react-icons/fa';
import { BiFridge, BiCabinet } from 'react-icons/bi';
import { GiSpikedFence  } from 'react-icons/gi';
import {MdHouseSiding} from 'react-icons/md'
import { TfiRulerAlt2 } from 'react-icons/tfi'
import {HiSquare2Stack} from 'react-icons/hi2'

export default class ServiceThree extends React.Component {
   
    render(){
        const publicUrl = process.env.PUBLIC_URL + '/'
        // const services = [
        //    'Residential', 'Commercial / Office', 'Post Construction', 'Move out cleaning', 'Deep Cleaning',
        //    'Reoccurring Cleaning', 'Emergency Cleaning', 'Floor Cleaning'
        // ]
        const services = [
            `**Commercial Cleaning Services:
            Janitorial Services, Office Cleaning, Carpet Cleaning, Floor Cleaning, Post Construction Cleaning, Building Cleaning Maintenance.`,
            '** Residential Cleaning Services: Move In/Out Cleaning, Deep Cleaning, General Cleaning',
            '**Cleaning & Disinfecting Cleaning programs are for Business Office Cleaning, Fitness Center/Gym Cleaning, Healthcare Centers, Retails, Building Cleaning Maintenance.'
         ]
 
        return (
            <>
                <section className="services-three">
                    <div className="shape1"><img src={publicUrl+"assets/images/shapes/services-v3-shape1.png"} alt="#" /></div>
                    <div className="shape2 rotate-me"><img src={publicUrl+"assets/images/update1.0/services-v3-shape2.png"} alt="#" /></div>
                    <div className="container">
                        <div className="serviceHeader">
                            <div className="col-xl-12">
                                <div className="services-three__top">
                                    <div className="sec-title">
                                        <div className="sec-title__tagline">
                                            <span className="left"></span><h6> COMPANY SERVICES </h6> <span className="right"></span> <br/>
                                            {/* <p> We clean it all!! </p> */}
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>

                        <div className="row">
                           
                               
                                    <div className="col-xl-3 col-lg-6 col-md-6" style={{ marginBottom: '10px' }}>
                                        <div className="services-three__single">
                                            <h3 className="services-three__text">Commercial Services: <br></br> 
                                               
                                            </h3>
                                            <ul>
                                                <li>Building Cleaning Maintenance</li>
                                                <li>Janitorial Services</li>
                                                <li>Office Cleaning</li>
                                                <li>Carpet Cleaning</li>
                                                <li>Floor Cleaning</li>
                                            </ul>

                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-md-6" style={{ marginBottom: '10px' }}>
                                        <div className="services-three__single">
                                            <h3 className="services-three__text">Residential Services: 
                                            <br></br>
                                            </h3>
                                            <ul>
                                                <li>Move In/Out Cleaning</li>
                                                <li>Deep Cleaning</li>
                                                <li>General Cleaning</li>
                                            </ul>
                                          
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-md-6">
                                        <div className="services-three__single">
                                            <h3 className="services-three__text">
                                            Cleaning & Disinfecting Cleaning programs are for Business Office Cleaning<br></br>
                                            </h3>
                                            <ul>
                                                <li>Fitness Center/Gym Cleaning</li>
                                                <li>Healthcare Centers</li>
                                                <li>Retails</li>
                                                <li>Building Cleaning Maintenance</li>
                                            </ul>
                                            
                                        </div>
                                    </div>
                                    
                                    
                                
                          
                        </div>
                    </div>
                </section>
            </>
        )
    }
}


