import React from 'react';

export default class TestimonialThree extends React.Component {
    componentDidMount() {

        const $ = window.$;
        
        if ($(".testimonials-three__carousel").length) {
            $(".testimonials-three__carousel").owlCarousel({
              loop: true,
              margin: 50,
              nav: true,
              smartSpeed: 500,
              autoHeight: false,
              autoplay: true,
              dots: false,
              autoplayTimeout: 10000,
              navText: [
                '<i class="icon-right-arrow left"></i>',
                '<i class="icon-right-arrow"></i>',
              ],
              responsive: {
                0: {
                  items: 1,
                },
                600: {
                  items: 1,
                },
                800: {
                  items: 2,
                },
                992: {
                  items: 2,
                },
                1200: {
                  items: 2,
                },
              },
            });
        }
          
    }
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <section className="testimonials-three">
                    <div className="testimonials-three__img1 zoom-fade">
                        <img src={publicUrl+"assets/images/update1.0/testimonials-v3-img7.jpg"} alt="#" />
                    </div>

                    <div className="testimonials-three__img2 float-bob-y">
                        <img src={publicUrl+"assets/images/update1.0/testimonials-v3-img3.jpg"} alt="#" />
                    </div>

                    <div className="testimonials-three__img3 float-bob-y">
                        <img src={publicUrl+"assets/images/update1.0/testimonials-v3-img10.jpg"} alt="#" />
                    </div>

                    <div className="testimonials-three__img4 zoom-fade">
                        <img src={publicUrl+"assets/images/update1.0/testimonials-v3-img10.jpg"} alt="#" />
                    </div>

                    <div className="testimonials-three__img5 zoom-fade">
                        <img src={publicUrl+"assets/images/update1.0/testimonials-v3-img11.jpg"} alt="#" />
                    </div>

                    <div className="testimonials-three__img6 float-bob-y">
                        <img src={publicUrl+"assets/images/update1.0/testimonials-v3-img8.jpg"} alt="#" />
                    </div>

                    <div className="testimonials-three__img7 zoom-fade">
                        <img src={publicUrl+"assets/images/update1.0/testimonials-v3-img6.jpg"} alt="#" />
                    </div>

                    <div className="testimonials-three__img8 zoom-fade">
                        <img src={publicUrl+"assets/images/update1.0/testimonials-v3-img5.jpg"} alt="#" />
                    </div>

                    <div className="testimonials-three__img9 float-bob-y">
                        <img src={publicUrl+"assets/images/update1.0/testimonials-v3-img9.jpg"} alt="#" />
                    </div>

                    <div className="container">
                        <div className="sec-title">
                            <div className="sec-title__tagline">
                                <span className="left"></span><h6>Client Testimonials</h6> <span className="right"></span>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xl-12">
                                <div className="testimonials-three__carousel owl-carousel owl-theme">
                                    {/* Start Testimonials Three Single */}
                                    <div className="testimonials-three__single">
                                        <div className="quote-icon">
                                            <span className="fa fa-quote-right"></span>
                                        </div>
                                        <div className="img-box">
                                            <img src={publicUrl+"assets/images/avatars/Male_Avatar.jpg"} alt="#" />
                                        </div>
                                        <div className="testimonials-three__single-inner">
                                            <div className="title-box">
                                                <h3>Carol K.</h3>
                                                <span>Romulus</span>
                                            </div>

                                            <div className="text-box">
                                                <p>
                                                    Today was the first time that I have used S&R cleaning and I am really impressed.
                                                    She is very hard working, accommodating, and my house looked so much better. 
                                                    Great job in cleaning the bathroom, it really sparkles.
                                                </p>
                                            </div>

                                            <ul className="rating-box">
                                                <li><span className="icon-star"></span></li>
                                                <li><span className="icon-star"></span></li>
                                                <li><span className="icon-star"></span></li>
                                                <li><span className="icon-star"></span></li>
                                                <li><span className="icon-star"></span></li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/* End Testimonials Three Single */}

                                    {/* Start Testimonials Three Single */}
                                    <div className="testimonials-three__single">
                                        <div className="quote-icon">
                                            <span className="fa fa-quote-right"></span>
                                        </div>
                                        <div className="img-box">
                                            <img src={publicUrl+"assets/images/avatars/Female-Avatar.png"} alt="#" />
                                        </div>
                                        <div className="testimonials-three__single-inner">
                                            <div className="title-box">
                                                <h3>Justin B.</h3>
                                                <span>Wayne</span>
                                            </div>

                                            <div className="text-box">
                                                <p>
                                                   Best cleaning service I've found so far, very professional and quality work. Their experience 
                                                   really shows I will be using them from now on. I definitely recommend them.
                                                </p>
                                            </div>

                                            <ul className="rating-box">
                                                <li><span className="icon-star"></span></li>
                                                <li><span className="icon-star"></span></li>
                                                <li><span className="icon-star"></span></li>
                                                <li><span className="icon-star"></span></li>
                                                <li><span className="icon-star"></span></li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/* End Testimonials Three Single */}

                                    {/* Start Testimonials Three Single */}
                                    <div className="testimonials-three__single">
                                        <div className="quote-icon">
                                            <span className="fa fa-quote-right"></span>
                                        </div>
                                        <div className="img-box">
                                            <img src={publicUrl+"assets/images/avatars/Female-Avatar.png"} alt="#" />
                                        </div>
                                        <div className="testimonials-three__single-inner">
                                            <div className="title-box">
                                                <h3>Karmen M.</h3>
                                                <span>Belleville</span>
                                            </div>

                                            <div className="text-box">
                                                <p>
                                                   Would recommend! They worked hard and steadily and cleaned away a ton of dust bunnies and grime.
                                                   The house is cleaner and freasher. They struck me as honest, professional and hardworking. I will
                                                   try them again. Thank you!!
                                                </p>
                                            </div>

                                            <ul className="rating-box">
                                                <li><span className="icon-star"></span></li>
                                                <li><span className="icon-star"></span></li>
                                                <li><span className="icon-star"></span></li>
                                                <li><span className="icon-star"></span></li>
                                                <li><span className="icon-star"></span></li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/* End Testimonials Three Single */}

                                    {/* Start Testimonials Three Single */}
                                    <div className="testimonials-three__single">
                                        <div className="quote-icon">
                                            <span className="fa fa-quote-right"></span>
                                        </div>
                                        <div className="img-box">
                                            <img src={publicUrl+"assets/images/avatars/Female-Avatar.png"} alt="#" />
                                        </div>
                                        <div className="testimonials-three__single-inner">
                                            <div className="title-box">
                                                <h3>Cheryl S.</h3>
                                                <span>Detroit</span>
                                            </div>

                                            <div className="text-box">
                                                <p>
                                                   Sharmaine and her associates were on time and did a very good job. It was the first
                                                   time I have used the service, but plan to continue.
                                                </p>
                                            </div>

                                            <ul className="rating-box">
                                                <li><span className="icon-star"></span></li>
                                                <li><span className="icon-star"></span></li>
                                                <li><span className="icon-star"></span></li>
                                                <li><span className="icon-star"></span></li>
                                                <li><span className="icon-star"></span></li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/* End Testimonials Three Single */}

                                    {/* Start Testimonials Three Single */}
                                    <div className="testimonials-three__single">
                                        <div className="quote-icon">
                                            <span className="fa fa-quote-right"></span>
                                        </div>
                                        <div className="img-box">
                                            <img src={publicUrl+"assets/images/avatars/Female-Avatar.png"} alt="#" />
                                        </div>
                                        <div className="testimonials-three__single-inner">
                                            <div className="title-box">
                                                <h3>Edwin W.</h3>
                                                <span>Dearborn</span>
                                            </div>

                                            <div className="text-box">
                                                <p>They did a great job and I look forward to having them clean every two weeks.
                                                    Excellent job.
                                                </p>
                                            </div>

                                            <ul className="rating-box">
                                                <li><span className="icon-star"></span></li>
                                                <li><span className="icon-star"></span></li>
                                                <li><span className="icon-star"></span></li>
                                                <li><span className="icon-star"></span></li>
                                                <li><span className="icon-star"></span></li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/* End Testimonials Three Single */}

                                    {/* Start Testimonials Three Single */}
                                    <div className="testimonials-three__single">
                                        <div className="quote-icon">
                                            <span className="fa fa-quote-right"></span>
                                        </div>
                                        <div className="img-box">
                                            <img src={publicUrl+"assets/images/avatars/Male_Avatar.jpg"} alt="#" />
                                        </div>
                                        <div className="testimonials-three__single-inner">
                                            <div className="title-box">
                                                <h3>Rick K.</h3>
                                                <span>Canton</span>
                                            </div>

                                            <div className="text-box">
                                                <p>Sharmaine and Jenny cleaned our house abd did an awesome job. Paid attention to detail
                                                    and very friendly. Went above and beyond a normal cleaning.
                                                </p>
                                            </div>

                                            <ul className="rating-box">
                                                <li><span className="icon-star"></span></li>
                                                <li><span className="icon-star"></span></li>
                                                <li><span className="icon-star"></span></li>
                                                <li><span className="icon-star"></span></li>
                                                <li><span className="icon-star"></span></li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/* End Testimonials Three Single */}
                                    {/* Start Testimonials Three Single */}
                                    <div className="testimonials-three__single">
                                        <div className="quote-icon">
                                            <span className="fa fa-quote-right"></span>
                                        </div>
                                        <div className="img-box">
                                            <img src={publicUrl+"assets/images/avatars/Female-Avatar.png"} alt="#" />
                                        </div>
                                        <div className="testimonials-three__single-inner">
                                            <div className="title-box">
                                                <h3>Beatrice L.</h3>
                                                <span>Dearborn</span>
                                            </div>

                                            <div className="text-box">
                                                <p>
                                                    Third cleaner I have tried and the only one that met my picky bar :). Thank you 
                                                    for your cleaning services! We will definitely have you back!
                                                </p>
                                            </div>

                                            <ul className="rating-box">
                                                <li><span className="icon-star"></span></li>
                                                <li><span className="icon-star"></span></li>
                                                <li><span className="icon-star"></span></li>
                                                <li><span className="icon-star"></span></li>
                                                <li><span className="icon-star"></span></li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/* End Testimonials Three Single */}

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}