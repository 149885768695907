import React from 'react';
import {Link} from 'react-router-dom';

export default class AboutFour extends React.Component {
    componentDidMount() {

        const $ = window.$;
        
        if ($(".video-popup").length) {
            $(".video-popup").magnificPopup({
              type: "iframe",
              mainClass: "mfp-fade",
              removalDelay: 160,
              preloader: true,
        
              fixedContentPos: false,
            });
        }
          
    }
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <div className="about-three">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6">
                                <div className="about-three__img">
                                    <div className="inner">
                                        <img src={publicUrl+"assets/images/16.jpeg"} alt="#" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-6">
                                <div className="about-three__content">
                                    <div className="sec-title">
                                        <div className="sec-title__tagline">
                                            <span className="left" ></span><h6 style={{ color: '#7ADB51' }}> ABOUT US </h6> <span className="right"></span>
                                        </div>
                                    </div>

                                    <div className="about-three__content-inner">
                                        <div className="text1">
                                            <p>
                                           Locally owned and operated, Scrub and Rinse Cleaning LLC delivers exceptional service by putting the quality of our work
                                           above above all else. We offer a variety of skills and services to assist all your cleaning needs. <br></br>
                                           Our employees are experienced, respectful, and knowledgeable. Some of our core values include integrity, premier service, and customer
                                           satisfaction. We pride ourselves on our competitive pricing and 100% satisfaction guarantee. <br></br>
                                           Our mission is to consistently provide the highest quality work and the best customer service in the cleaning industry.
                                            </p>
                                        </div>
                                       
                                        <ul className="about-three__content-list">
                                            <li>
                                                <div className="icon-box">
                                                    <span className="icon-tick"></span>
                                                </div>
                                                <div className="text-box">
                                                    <p> Residential </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon-box">
                                                    <span className="icon-tick"></span>
                                                </div>
                                                <div className="text-box">
                                                    <p> Commercial </p>
                                                </div>
                                            </li>
                                            
                                        </ul>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}