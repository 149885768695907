import React from 'react';
//  import {Link} from "react-router-dom";
import { Link as LinkRoll } from 'react-scroll'


const Nav = () => {
    return (
        <div className="main-menu text-center">
            <nav>
                <ul className="main-menu__list">
                    <li>
                        <LinkRoll active Class="active" to="services" spy={true} smooth={true} offset={50} duration={500} >Services</LinkRoll>
                    </li>
                    <li>
                        <LinkRoll active Class="active" to="about" spy={true} smooth={true} offset={50} duration={500} >About</LinkRoll>
                    </li>
                    <li>
                        <LinkRoll active Class="active" to="portfolio" spy={true} smooth={true} offset={50} duration={500}>Portfolio</LinkRoll>
                    </li>
                    <li>
                        <LinkRoll active Class="active" to="faq" spy={true} smooth={true} offset={50} duration={500}>FAQ</LinkRoll>
                    </li>
                    <li>
                        <LinkRoll active Class="active" to="testimonials" spy={true} smooth={true} offset={50} duration={500}>Testimonials</LinkRoll>
                    </li>
                    {/* <li >
                        <LinkRoll active Class="active" to="blog" spy={true} smooth={true} offset={50} duration={500}>Design Tips</LinkRoll>
                    </li> */}
                </ul>
            </nav>
        </div>
    )
}

export default Nav;


