import React from 'react';

export default class LogoBox extends React.Component {
    render(){
        const publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <div className="logo-box">
                    <img src={publicUrl+"assets/images/Scrub (1).jpg"} alt="Logo" />
                </div>
            </>
        )
    }
}