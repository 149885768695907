import React from 'react';
import {Link} from 'react-router-dom';
import { AiOutlineInstagram } from 'react-icons/ai';
import { BsFacebook } from 'react-icons/bs'
import { Link as LinkRoll } from 'react-scroll'


export default class FooterOne extends React.Component {
    render(){
        const publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <footer className="footer-one-sec">
                    <div className="footer-one__pattern" style={{backgroundImage: 'url('+publicUrl+'assets/images/pattern/footer-v1-pattern.png)'}}></div>
                    <div className="footer-one__top">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="footer-one__top-inner">
                                        <div className="row">
                                            <div className="col-xl-4 col-lg-6 col-md-6 wow animated fadeInUp" data-wow-delay="0.1s">
                                                <div className="footer-widget__column footer-widget__about">
                                                    <div className="footer-widget__about-logo">
                                                        <img src={publicUrl + "assets/images/Scrub (1).jpg"} alt="Logo" />
                                                        <div className='footer_contact_info'>
                                                            
                                                            <p>
                                                               
                                                               <span className="icon-phone-call"></span> <a href="tel:7347894178">Call Us</a> <br />
                                                                <span className="icon-email"></span> 
                                                                <a href="mailto:scrub.rinse.co@gmail.com">Scrub & Rinse Cleaning</a>
                                                            </p>
                                                            
                                                        </div>
                                                        
                                                    </div>

                                                    
                                                </div>
                                                <div className="footer-widget__about-social-link">
                                                        <p>Get social with us!</p>
                                                        <ul>
                                                            <li>
                                                                <a href="https://www.instagram.com/scrub.rinse.co/">
                                                                    <span className="first "><AiOutlineInstagram/></span>
                                                                    <span className="second "><AiOutlineInstagram/></span>
                                                                </a>
                                                            </li>

                                                            <li>
                                                                <a href="https://www.facebook.com/scrubandrinsecleaning/">
                                                                    <span className="first "><BsFacebook/></span>
                                                                    <span className="second"><BsFacebook/></span>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>

                                            <div className="col-xl-2 col-lg-6 col-md-6 wow animated fadeInUp" data-wow-delay="0.3s">
                                                <div className="footer-widget__column footer-widget__services">
                                                    {/* <h2 className="footer-widget__title">Our Services</h2> */}
                                                    <ul className="footer-widget__services-list">
                                                        
                                                        <li className="footer-widget__services-list-item">
                                                            <LinkRoll active Class="active" to="services" spy={true} smooth={true} offset={50} duration={500} >Company Services</LinkRoll>
                                                        </li>
                                                        <li className="footer-widget__services-list-item">
                                                            <LinkRoll active Class="active" to="about" spy={true} smooth={true} offset={50} duration={500} >About Us</LinkRoll>
                                                        </li>
                                                        <li className="footer-widget__services-list-item">
                                                            <LinkRoll active Class="active" to="portfolio" spy={true} smooth={true} offset={50} duration={500}>Portfolio</LinkRoll>
                                                        </li>
                                                        <li className="footer-widget__services-list-item">
                                                            <LinkRoll active Class="active" to="faq" spy={true} smooth={true} offset={50} duration={500}>FAQ</LinkRoll>
                                                        </li>
                                                        <li className="footer-widget__services-list-item">
                                                            <LinkRoll active Class="active" to="testimonials" spy={true} smooth={true} offset={50} duration={500}>Testimonials</LinkRoll>
                                                        </li>
                                                            
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-lg-6 col-md-6 wow animated fadeInUp" data-wow-delay="0.7s">
                                                <div className="footer-widget__column footer-widget__posts">
                                                    <h2 className="footer-widget__title">Home Cleaning Tips</h2>
                                                    <div className="footer-widget__posts-list">
                                                        <ul>
                                                            <li>
                                                                <div className="img-box">
                                                                    <img src={publicUrl+"assets/images/7secretstocleanhouse.jpeg"} alt="" />
                                                                    <div className="overlay-icon">
                                                                        <a href={'https://www.10news.com/7-secrets-always-having-clean-house#:~:text=Clean%20a%20Little%20Every%20Day&text=So%2C%20consider%20tackling%20a%20single,on%20track%2C%20task%20by%20task.'}>
                                                                           
                                                                        </a>
                                                                    </div>
                                                                </div>

                                                                <div className="text-box">
                                                                    <p><a href={'https://www.10news.com/7-secrets-always-having-clean-house#:~:text=Clean%20a%20Little%20Every%20Day&text=So%2C%20consider%20tackling%20a%20single,on%20track%2C%20task%20by%20task.'}>
                                                                        7 secrets to always having a clean house
                                                                    </a></p>
                                                                    
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="img-box">
                                                                    <img src={publicUrl+"assets/images/deepcleaning.webp"} alt="" />
                                                                    <div className="overlay-icon">
                                                                        <a href={'https://www.homemadesimple.com/in-the-home/ultimate-deep-cleaning-house-checklist/'}>
                                                                            {/* <span className="icon-link"></span> */}
                                                                        </a>
                                                                    </div>
                                                                </div>

                                                                <div className="text-box">
                                                                    <p>
                                                                        <a href={'https://www.homemadesimple.com/in-the-home/ultimate-deep-cleaning-house-checklist/'}>
                                                                        A Comprehensive Checklist for Deep Cleaning Your House
                                                                        </a>
                                                                    </p>
                                                                    
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="img-box">
                                                                    <img src={publicUrl+"assets/images/50cleaningtips.webp"} style={{height: 46, width: 70}} alt="" />
                                                                    <div className="overlay-icon">
                                                                        <a href={'https://www.thespruce.com/best-cleaning-tips-7372305'}>
                                                                            {/* <span className="icon-link"></span> */}
                                                                        </a>
                                                                    </div>
                                                                </div>

                                                                <div className="text-box">
                                                                    <p>
                                                                        <a href={'https://www.thespruce.com/best-cleaning-tips-7372305'}>
                                                                            50 Best Cleaning Tips
                                                                        </a>
                                                                    </p>
                                                                    
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="footer-one__bottom clearfix">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="footer-one__bottom-inner">
                                        <div className="footer-one__bottom-text">
                                            <p>
                                                Copyright &copy;2023. All rights reserved to <br/>
                                                <span id='companyName'> Scrub & Rinse Cleaning LLC </span>

                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </>
        )
    }
}